<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="bill.id == 0" class="card-title">Bill Creation</h5>
        <h5 v-if="bill.id > 0" class="card-title"> Bill Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <p/>
        <form action="#">

        <div class="form-group row">
          <label class="col-md-1 col-form-label">Bill No:</label>
          <div class="col-md-5">
            <input  ref="txtBillNo" type="text" class="form-control" placeholder="Enter Bill No" maxlength="10" v-if="bill" v-model="bill.bill_no" autofocus autocomplete="off">
          </div>

          <label class="col-md-1 col-form-label">Date:</label>
          <div class="col-md-5">
            <input  ref="txtDate"  type="date" class="form-control" placeholder="Enter Project Name here..." maxlength="100" v-if="bill" v-model="bill.doc_date" >
          </div>
        </div>

        <div class="form-group row">

          <label class="col-md-1 col-form-label">Supplier:</label>
          <div class="col-md-5">
            <select class="form-control" required="" v-if="bill" v-model="bill.supplier_id">
              <option  v-for="supplier in suppliers" :vale="supplier.id" >  {{supplier.name}} </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Project:</label>
          <div class="col-md-5">
            <select class="form-control" required="" v-if="bill" v-model="bill.project_id">
              <option  v-for="project in projects" :vale="project.id" >  {{project.name}} </option>
            </select>
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-1 col-form-label">Material:</label>
          <div class="col-md-5">
            <select class="form-control" required="" v-if="bill" v-model="bill.material_id">
              <option  v-for="material in rawmaterials" :vale="material.id" >  {{material.name}} </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Type</label>
          <div class="col-md-5">
            <input type="number" class="form-control" placeholder="Enter Type" maxlength="100" v-if="bill" v-model="bill.type">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-1 col-form-label">Qty:</label>
          <div class="col-md-5">
            <input type="number" class="form-control" placeholder="Enter Billed Qty" maxlength="100" v-if="bill" v-model="bill.qty">
          </div>

          <label class="col-md-1 col-form-label">Amount:</label>
          <div class="col-md-5">
            <input type="number" class="form-control" placeholder="Enter Billed Qty" maxlength="100" v-if="bill" v-model="bill.amount">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import moment from 'moment-timezone'
import { store } from '@/store/store.js'
export default {
  name: 'BillForm',
  store,
  data () {
    return {
      bill: JSON.parse('{"id":0,"type":0,"doc_date":"","bill_no":"","project_id":0,"supplier_id":0,"material_id":0,"qty":0,"amount":0}'),
      projects : [],
      suppliers : [],
      rawmaterials:[],
    }
  },
  props: {
    mybill: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"type":0,"doc_date":"","bill_no":"","project_id":0,"supplier_id":0,"material_id":0,"qty":0,"amount":0}')
    }
  },
  beforeMount () {
    this.bill = this.mybill; // save props data to itself's data and deal with tms
  },

  component: {

  },
  created () {
    this.$data.bill = JSON.parse('{"id":0,"type":0,"doc_date":"","bill_no":"","project_id":0,"supplier_id":0,"material_id":0,"qty":0,"amount":0}')
  },
  mounted () {
    const self = this;
    self.$data.bill.doc_date = moment().format('YYYY-MM-DD');

    try {
      self.loadProjects();
      self.loadRawmaterials();
      self.loadSuppliers();
    }catch (e) {
      alert(e);
    }

    self.$refs.txtBillNo.focus();
  },
  methods: {
    closeWindow(){
      this.$emit('bill_window_closed');
      // this.$modal.hide('bill_window');
      this.bill = JSON.parse('{"id":0,"type":0,"doc_date":"","bill_no":"","project_id":0,"supplier_id":0,"material_id":0,"qty":0,"amount":0}');
    },
    clear(){
      this.$data.bill = JSON.parse('{"id":0,"type":0,"doc_date":"","bill_no":"","project_id":0,"supplier_id":0,"material_id":0,"qty":0,"amount":0}');
      this.$data.bill.doc_date = moment().format('YYYY-MM-DD');
    },
    loadAll(){
      const self = this;
      self.loadProjects();
      self.loadRawmaterials();
      self.loadSuppliers();

      self.$refs.txtBillNo.focus();
    },
    loadProjects(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        credentials: 'same-origin',
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.projects = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/projects`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray (resp.data)) {
            self.$data.projects = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    loadSuppliers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        credentials: 'same-origin',
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.suppliers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/suppliers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray (resp.data)) {
            self.$data.suppliers = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    loadRawmaterials(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        credentials: 'same-origin',
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.rawmaterials = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/rawmaterials`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray (resp.data)) {
            self.$data.rawmaterials = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    saveDocument(){
      const self = this;

      if(self.$data.bill.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      self.$data.bill.type = parseInt(self.$data.bill.type);

      const requestOptions = {
        method:  (self.$data.bill.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.bill)
      };

      // console.log(JSON.stringify(self.$data.bill));

      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/bill`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$emit('bill_saved', resp.data);
          swal ( { title: "Success!" ,  text: resp.msg, type: "success"} );
          self.bill = JSON.parse('{"id":0,"type":0,"doc_date":"","bill_no":"","project_id":0,"supplier_id":0,"material_id":0,"qty":0,"amount":0}');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"});
      });
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
